'use strict';

var account = require('./../../../../app_storefront_core/cartridge/js/pages/account'),
	bonusProductsView = require('./../../../../app_storefront_core/cartridge/js/bonus-products-view'),
	quickview = require('./../quickview'),
	cartStoreInventory = require('./../../../../app_storefront_core/cartridge/js/storeinventory/cart'),
    productTile = require('../product-tile'),
    orderDuties = require('./../util/order-duties'),
    btcRates = require('./product/btcRates');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    if ($('.js-gift-wrapping').length) {
        $('input[name$="_isGiftWrap"]').val($('.js-gift-wrapping').prop('checked'));
    }

	$('#cart-table').on('click', '.js-order_product-edit_link', function (e) {
		e.preventDefault();
		quickview.show({
			url: $(this).data('href'),
			source: 'cart'
		});
	})
	.on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
		e.preventDefault();
		bonusProductsView.show(this.href);
	})
	.one('change', '.js-cart_quantity_field', function (e) {
		$('.js-cart-update').attr('disabled', true);
		setTimeout(function () {
			$('#cart-items-form').submit();
		}, 2000);
	});
	// override enter key for coupon code entry
	$('form input[name$="_couponCode"]').on('keydown', function (e) {
		if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    $('.js-gift-wrapping').on('change', function(e){
        $('input[name$="_isGiftWrap"]').val($(this).prop('checked'));
    });

	//to prevent multiple submissions of the form when removing a product from the cart
	var removeItemEvent = false;
	$('button[name$="deleteProduct"]').on('click', function (e) {
		if (removeItemEvent) {
			e.preventDefault();
		} else {
			removeItemEvent = true;
		}
	});

	//validate coupon code
  var $couponInput = $('input[name$="_couponCode"]');
  var $error = $('form .js-coupon-error');
  var $addCouponButton = $('#add-coupon');

  // Validate coupon code function
  function validateCoupon() {
    const code = $couponInput.val().trim();

    if (code.length === 0) {
      $error.html(Resources.COUPON_CODE_MISSING);
      return false;
    }

    $error.empty();
    return true;
  }

  $couponInput.on('keyup', function (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();

      if (validateCoupon()) {
        $addCouponButton.trigger('click');
      }
    }
  });

  $addCouponButton.on('click', function (e) {
    if (!validateCoupon()) {
      e.preventDefault();
    }
  });

	//Init productTile for Predictive recommendations
	document.addEventListener('recommendations.loaded', function () {
		productTile.init();
	});
    orderDuties.init();
    btcRates.addCryptoPriceOnCheckout();

}

exports.init = function () {
	initializeEvents();
	if (SitePreferences.STORE_PICKUP) {
		cartStoreInventory.init();
	}
	account.initCartLogin();
};
